import React, { useEffect, useState } from 'react';
import styles from './RecipeForm.module.css';

function RecipeForm({
  recipe = null,
  onClose,
  onRecipeAdded,
  onRecipeUpdated,
  isEdit = false,
  handleDelete,
}) {
  // Initialize state variables
  const [title, setTitle] = useState('');
  const [dataSourceName, setDataSourceName] = useState('');
  const [prepTime, setPrepTime] = useState('');
  const [waitTime, setWaitTime] = useState('');
  const [cookTime, setCookTime] = useState('');
  const [servings, setServings] = useState('');
  const [calories, setCalories] = useState('');
  const [fat, setFat] = useState('');
  const [satFat, setSatFat] = useState('');
  const [carbs, setCarbs] = useState('');
  const [fiber, setFiber] = useState('');
  const [sugar, setSugar] = useState('');
  const [protein, setProtein] = useState('');
  const [instructions, setInstructions] = useState([
    { step_number: 1, instruction: '' },
  ]);
  const [ingredients, setIngredients] = useState([
    { ingredient_name: '', brand: '', quantity: '', unit: '', method: '', optional: '' },
  ]);
  const [tags, setTags] = useState(['']);
  const [equipment, setEquipment] = useState(['']);
  const [imageFile, setImageFile] = useState(null); // Changed from imageUrl to imageFile

  // Options for datalist inputs
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage

        // Run API calls in parallel for better performance
        const [ingredientsRes, unitsRes, equipmentRes] = await Promise.all([
          fetch('/api/ingredients-names', {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization token
            },
          }),
          fetch('/api/units', {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization token
            },
          }),
          fetch('/api/equipments-names', {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization token
            },
          }),
        ]);

        // Check all responses
        if (!ingredientsRes.ok) {
          throw new Error('Failed to fetch ingredient names');
        }
        if (!unitsRes.ok) {
          throw new Error('Failed to fetch unit names');
        }
        if (!equipmentRes.ok) {
          throw new Error('Failed to fetch equipment names');
        }

        // Parse JSON data
        const ingredientsData = await ingredientsRes.json();
        const unitsData = await unitsRes.json();
        const equipmentData = await equipmentRes.json();

        // Update state with fetched data
        setIngredientOptions(ingredientsData);
        setUnitOptions(unitsData);
        setEquipmentOptions(equipmentData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Initialize form fields when recipe prop changes (for editing)
  useEffect(() => {
    if (recipe) {
      setTitle(recipe.title || '');
      setDataSourceName(recipe.data_source_name || '');
      setPrepTime(recipe.prep_time_min || '');
      setWaitTime(recipe.wait_time_min || '');
      setCookTime(recipe.cook_time_min || '');
      setServings(recipe.servings || '');
      setCalories(recipe.calories || '');
      setFat(recipe.fat || '');
      setSatFat(recipe.sat_fat || '');
      setCarbs(recipe.carbs || '');
      setFiber(recipe.fiber || '');
      setSugar(recipe.sugar || '');
      setProtein(recipe.protein || '');
      setInstructions(
        recipe.instructions || [{ step_number: 1, instruction: '' }]
      );
      setIngredients(
        recipe.ingredients || [
          { ingredient_name: '', quantity: '', unit: '', method: '', brand: '', optional: '' },
        ]
      );
      setTags(recipe.tags || ['']);
      setEquipment(recipe.equipment || ['']);
      setImageFile(null); // Reset imageFile when editing
    } else {
      // If no recipe is provided (adding), reset form fields
      resetForm();
    }
  }, [recipe]);

  const resetForm = () => {
    setTitle('');
    setDataSourceName('');
    setPrepTime('');
    setWaitTime('');
    setCookTime('');
    setServings('');
    setCalories('');
    setFat('');
    setSatFat('');
    setCarbs('');
    setFiber('');
    setSugar('');
    setProtein('');
    setInstructions([{ step_number: 1, instruction: '' }]);
    setIngredients([
      { ingredient_name: '', quantity: '', unit: '', method: '', brand: '', optional: '' },
    ]);
    setTags(['']);
    setEquipment(['']);
    setImageFile(null); // Reset imageFile
  };

  const handleAddRecipeSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('title', title);
    formData.append('data_source_name', dataSourceName);
    formData.append('prep_time_min', prepTime);
    formData.append('wait_time_min', waitTime);
    formData.append('cook_time_min', cookTime);
    formData.append('servings', servings);
    formData.append('calories', calories);
    formData.append('fat', fat);
    formData.append('sat_fat', satFat);
    formData.append('carbs', carbs);
    formData.append('fiber', fiber);
    formData.append('sugar', sugar);
    formData.append('protein', protein);

    formData.append(
      'instructions',
      JSON.stringify(
        instructions.map((instr, index) => ({
          step_number: index + 1,
          instruction: instr.instruction,
        }))
      )
    );

    formData.append('ingredients', JSON.stringify(ingredients));
    formData.append('tags', JSON.stringify(tags));
    formData.append('equipment', JSON.stringify(equipment));

    if (imageFile) {
      formData.append('image', imageFile); // Append the image file
    }

    try {
      const response = await fetch('/api/recipes/add', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        resetForm();
        if (onRecipeAdded) {
          onRecipeAdded();
        }
        onClose();
      } else {
        const data = await response.json();
        alert(data.message || 'Failed to add recipe');
      }
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  const handleEditRecipeSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('title', title);
    formData.append('data_source_name', dataSourceName);
    formData.append('prep_time_min', prepTime);
    formData.append('wait_time_min', waitTime);
    formData.append('cook_time_min', cookTime);
    formData.append('servings', servings);
    formData.append('calories', calories);
    formData.append('fat', fat);
    formData.append('sat_fat', satFat);
    formData.append('carbs', carbs);
    formData.append('fiber', fiber);
    formData.append('sugar', sugar);
    formData.append('protein', protein);

    formData.append(
      'instructions',
      JSON.stringify(
        instructions.map((instr, index) => ({
          step_number: index + 1,
          instruction: instr.instruction,
        }))
      )
    );

    formData.append('ingredients', JSON.stringify(ingredients));
    formData.append('tags', JSON.stringify(tags));
    formData.append('equipment', JSON.stringify(equipment));

    if (imageFile) {
      formData.append('image', imageFile); // Append the image file
    }

    try {
      const response = await fetch(`/api/recipes/edit/${recipe.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        if (onRecipeUpdated) {
          onRecipeUpdated();
        }
        onClose();
      } else {
        const data = await response.json();
        alert(data.message || 'Failed to update recipe');
      }
    } catch (error) {
      console.error('Error updating recipe:', error);
    }
  };

  const handleSubmit = isEdit ? handleEditRecipeSubmit : handleAddRecipeSubmit;

  // Handle image file selection
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit} className={styles['addrecipe-form']}>

    <button
      type="button"
      className={styles['addrecipe-form-top-exit-button']}
      onClick={onClose}
    >
      ✖
    </button>

      {/* Basic Information */}
      <h2 className={styles['addrecipe-form-section-title']}>
        Basic Information
      </h2>
      <div className={styles['addrecipe-form-group']}>
        <label htmlFor="title">
          Title<span className={styles['addrecipe-form-required']}>*</span>:
        </label>
        <input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>

      <div className={styles['addrecipe-form-group']}>
        <label htmlFor="dataSourceName">Data Source Name:</label>
        <input
          id="dataSourceName"
          type="text"
          value={dataSourceName}
          onChange={(e) => setDataSourceName(e.target.value)}
        />
      </div>

    {/* Image Upload */}
    <div className={styles['addrecipe-form-group']}>
      <label htmlFor="imageUploader">Upload Image:</label>
      <div className={styles['image-upload-container']}>
        {/* Hidden File Input */}
        <input
          id="imageUploader"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className={styles['image-upload-input']}
        />
        {/* Styled Button */}
        <button
          type="button"
          className={styles['image-upload-button']}
          onClick={() => document.getElementById('imageUploader').click()}
        >
          Select Image
        </button>
      </div>
      {imageFile && (
        <img
          src={URL.createObjectURL(imageFile)}
          alt="Selected"
          className={styles['image-preview']}
        />
      )}
      {!imageFile && isEdit && recipe.image_url && (
        <img
          src={recipe.image_url}
          alt="Current"
          className={styles['image-preview']}
        />
      )}
    </div>



      {/* Timing and Servings */}
      <h2 className={styles['addrecipe-form-section-title']}>
        Timing and Servings
      </h2>
      <div
        className={`${styles['addrecipe-form-group']} ${styles['grid-layout']}`}
      >
        <div className={styles['grid-item']}>
          <label htmlFor="prepTime">Prep Time (min):</label>
          <input
            id="prepTime"
            type="number"
            value={prepTime}
            onChange={(e) => setPrepTime(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="waitTime">Wait Time (min):</label>
          <input
            id="waitTime"
            type="number"
            value={waitTime}
            onChange={(e) => setWaitTime(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="cookTime">Cook Time (min):</label>
          <input
            id="cookTime"
            type="number"
            value={cookTime}
            onChange={(e) => setCookTime(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="servings">Servings:</label>
          <input
            id="servings"
            type="number"
            value={servings}
            onChange={(e) => setServings(e.target.value)}
            min="1"
          />
        </div>
      </div>

      {/* Nutrition Information */}
      <h2 className={styles['addrecipe-form-section-title']}>
        Nutrition Information
      </h2>
      <div
        className={`${styles['addrecipe-form-group']} ${styles['grid-layout']}`}
      >
        <div className={styles['grid-item']}>
          <label htmlFor="calories">Calories:</label>
          <input
            id="calories"
            type="number"
            value={calories}
            onChange={(e) => setCalories(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="fat">Fat (g):</label>
          <input
            id="fat"
            type="number"
            value={fat}
            onChange={(e) => setFat(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="satFat">Sat. Fat (g):</label>
          <input
            id="satFat"
            type="number"
            value={satFat}
            onChange={(e) => setSatFat(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="carbs">Carbs. (g):</label>
          <input
            id="carbs"
            type="number"
            value={carbs}
            onChange={(e) => setCarbs(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="fiber">Fiber (g):</label>
          <input
            id="fiber"
            type="number"
            value={fiber}
            onChange={(e) => setFiber(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="sugar">Sugar (g):</label>
          <input
            id="sugar"
            type="number"
            value={sugar}
            onChange={(e) => setSugar(e.target.value)}
            min="0"
          />
        </div>

        <div className={styles['grid-item']}>
          <label htmlFor="protein">Protein (g):</label>
          <input
            id="protein"
            type="number"
            value={protein}
            onChange={(e) => setProtein(e.target.value)}
            min="0"
          />
        </div>
      </div>

      {/* Instructions */}
      <h2 className={styles['addrecipe-form-section-title']}>Instructions</h2>
      <div className={styles['instructions-list']}>
        {instructions.map((instr, index) => (
          <div
            key={index}
            className={styles['instructions-container']}
          >
            <textarea
              id={`instruction-${index}`}
              className={styles['instruction-textarea']}
              placeholder={`Step ${index + 1}...`}
              value={instr.instruction}
              onChange={(e) =>
                setInstructions(
                  instructions.map((item, i) =>
                    i === index
                      ? { ...item, instruction: e.target.value }
                      : item
                  )
                )
              }
              required
            ></textarea>
            <div className={styles['instructions-buttons']}>
              <button
                type="button"
                className={`${styles['instruction-button']} ${styles['instruction-button-delete']}`}
                onClick={() =>
                  setInstructions(instructions.filter((_, i) => i !== index))
                }
              >
                Delete Step
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        type="button"
        className={`${styles['instruction-button']} ${styles['instruction-button-add']}`}
        onClick={() =>
          setInstructions([
            ...instructions,
            { step_number: instructions.length + 1, instruction: '' },
          ])
        }
      >
        Add Instruction
      </button>


      <h2 className={styles['addrecipe-form-section-title']}>Ingredients</h2>
        <div className={styles['ingredients-container']}>
          <div className={styles['ingredients-grid']}>
            {ingredients.map((ing, index) => (
              <div key={index} className={styles['ingredient-block']}>
                <div className={styles['ingredient-fields']}>
                  <div className={styles['ingredient-row']}>
                    {/* Ingredient Name */}
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-name-${index}`}>Ingredient Name:</label>
                      <input
                        id={`ingredient-name-${index}`}
                        type="text"
                        value={ing.ingredient_name}
                        onChange={(e) =>
                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index
                                ? { ...item, ingredient_name: e.target.value }
                                : item
                            )
                          )
                        }
                        list={`ingredient-options-${index}`}
                        placeholder="e.g., Onion, Mayo"
                        required
                      />
                      <datalist id={`ingredient-options-${index}`}>
                        {ingredientOptions.map((option) => (
                          <option key={option.id} value={option.ingredient_name} />
                        ))}
                      </datalist>
                    </div>

                    {/* brand */}
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-brand-${index}`}>Suggested Brand:</label>
                      <input
                        id={`ingredient-brand-${index}`}
                        type="text"
                        value={ing.brand || ''}
                        onChange={(e) =>
                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index
                                ? { ...item, brand: e.target.value }
                                : item
                            )
                          )
                        }
                        list={`ingredient-brand-options-${index}`}
                        placeholder="e.g., Red Onion, Low-Cal Mayo"
                      />
                      <datalist id={`ingredient-brand-options-${index}`}>
                        <option value="Red Onion" />
                        <option value="Low-Cal Mayo" />
                        <option value="Powdered Sugar" />
                        <option value="Sea Salt" />
                      </datalist>
                    </div>
                  </div>

                  <div className={styles['ingredient-row']}>
                    {/* Quantity */}
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-quantity-${index}`}>Quantity:</label>
                      <input
                        id={`ingredient-quantity-${index}`}
                        type="text"
                        value={
                          ing.rawQuantity ?? (ing.quantity !== null ? ing.quantity.toString() : '')
                        }
                        onChange={(e) => {
                          const input = e.target.value.trim();
                          let errorMessage = '';
                          let numericValue = null;

                          const isFraction = /^\d+\/\d+$/.test(input);
                          if (isFraction) {
                            const [numerator, denominator] = input.split('/').map(Number);
                            if (denominator !== 0) {
                              numericValue = numerator / denominator;
                            } else {
                              errorMessage = 'Denominator cannot be zero.';
                            }
                          } else if (!isNaN(parseFloat(input))) {
                            numericValue = parseFloat(input);
                          } else if (input !== '') {
                            errorMessage = 'Enter a valid number, fraction, or decimal.';
                          }

                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index
                                ? {
                                    ...item,
                                    rawQuantity: input,
                                    quantity: numericValue,
                                    error: errorMessage,
                                  }
                                : item
                            )
                          );
                        }}
                        placeholder="e.g., 1, 1.5, 1/2"
                        required
                      />
                      {ing.error && (
                        <span className={styles['error-message']}>{ing.error}</span>
                      )}
                    </div>

                    {/* Unit */}
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-unit-${index}`}>Unit:</label>
                      <input
                        id={`ingredient-unit-${index}`}
                        type="text"
                        value={ing.unit}
                        onChange={(e) =>
                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index ? { ...item, unit: e.target.value } : item
                            )
                          )
                        }
                        list="unit-options"
                        placeholder="e.g., grams, cups"
                      />
                      <datalist id="unit-options">
                        {unitOptions.map((option) => (
                          <option key={option} value={option} />
                        ))}
                      </datalist>
                    </div>
                  </div>

                  {/* Method Section */}
                  <div className={styles['ingredient-row']}>
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-method-${index}`}>Method:</label>
                      <input
                        id={`ingredient-method-${index}`}
                        type="text"
                        value={ing.method || ''}
                        onChange={(e) =>
                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index
                                ? { ...item, method: e.target.value }
                                : item
                            )
                          )
                        }
                        placeholder="e.g., diced, chopped"
                      />
                    </div>
                    <div className={styles['ingredient-field']}>
                      <label htmlFor={`ingredient-optional-${index}`}>Is it required?:</label>
                      <button
                        type="button"
                        className={`${styles['addrecipe-form-small-button']} ${styles['optional-button']}`}
                        onClick={() =>
                          setIngredients(
                            ingredients.map((item, i) =>
                              i === index
                                ? { ...item, optional: !item.optional }
                                : item
                            )
                          )
                        }
                      >
                        {ing.optional ? 'No' : 'Yes'}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Delete Button */}
                <button
                  type="button"
                  onClick={() =>
                    setIngredients(ingredients.filter((_, i) => i !== index))
                  }
                  className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-delete-button']}`}
                >
                  Delete Ingredient
                </button>
              </div>
            ))}
          </div>

          {/* Add Ingredient Button */}
          <button
            type="button"
            onClick={() =>
              setIngredients([
                ...ingredients,
                {
                  ingredient_name: '',
                  brand: '',
                  quantity: '',
                  unit: '',
                  method: '',
                  optional: false,
                },
              ])
            }
            className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-add-button']}`}
          >
            Add Ingredient
          </button>
        </div>




      {/* Tags */}
      <h2 className={styles['addrecipe-form-section-title']}>Tags</h2>
      <div
        className={`${styles['addrecipe-form-group']} ${styles['grid-layout']}`}
      >
        {tags.map((tag, index) => (
          <div key={index} className={styles['grid-item']}>
            <input
              id={`tag-${index}`}
              type="text"
              value={tag}
              onChange={(e) =>
                setTags(
                  tags.map((item, i) =>
                    i === index ? e.target.value : item
                  )
                )
              }
              placeholder="Enter tag"
            />
            <button
              type="button"
              onClick={() => setTags(tags.filter((_, i) => i !== index))}
              className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-delete-button']}`}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => setTags([...tags, ''])}
        className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-add-button']}`}
      >
        Add Tag
      </button>

      {/* Equipment */}
      <h2 className={styles['addrecipe-form-section-title']}>Equipment</h2>
      <div
        className={`${styles['addrecipe-form-group']} ${styles['grid-layout']}`}
      >
        {equipment.map((equip, index) => (
          <div key={index} className={styles['grid-item']}>
            <input
              id={`equipment-name-${index}`}
              type="text"
              value={equip}
              onChange={(e) =>
                setEquipment(
                  equipment.map((item, i) =>
                    i === index ? e.target.value : item
                  )
                )
              }
              list="equipment-options"
              placeholder="Equipment name"
              required
            />
            <datalist id="equipment-options">
              {equipmentOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.equipment_name}
                />
              ))}
            </datalist>
            <button
              type="button"
              onClick={() =>
                setEquipment(equipment.filter((_, i) => i !== index))
              }
              className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-delete-button']}`}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => setEquipment([...equipment, ''])}
        className={`${styles['addrecipe-form-small-button']} ${styles['addrecipe-form-add-button']}`}
      >
        Add Equipment
      </button>

      {/* Submit and Close Buttons */}
      <div className={styles['addrecipe-form-buttons']}>
        <button
          type="submit"
          className={styles['addrecipe-form-submit-button']}
        >
          {isEdit ? 'Save Changes' : 'Add Recipe'}
        </button>
        <button
          type="button"
          className={styles['addrecipe-form-close-button']}
          onClick={onClose}
        >
          Close
        </button>
        {isEdit && handleDelete && (
          <button
            type="button"
            className={`${styles['addrecipe-form-delete-button']} ${styles['main-delete-button']}`}
            onClick={handleDelete}
          >
            Delete Recipe
          </button>
        )}
      </div>
    </form>
  );
}

export default RecipeForm;
