import React, { useState, useEffect } from 'react';
import RecipeForm from './RecipeForm';
import RecipeModalMini from './RecipeModalMini';
import styles from './AddRecipe.module.css';

function AddRecipe() {
  // State variables
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [userRecipes, setUserRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // State to store the search input
  const [loading, setLoading] = useState(true); // State for loading spinner

  useEffect(() => {
    fetchUserRecipes();
  }, []);

  // Handle the search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    // Filter recipes based on the search query
    if (searchQuery.trim() === '') {
      setFilteredRecipes(userRecipes); // Reset to all recipes if the search query is empty
    } else {
      const filtered = userRecipes.filter((recipe) =>
        recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredRecipes(filtered);
    }
  }, [searchQuery, userRecipes]);

  const handleCloseAddRecipe = () => {
    setShowAddForm(false);
  };

  const fetchUserRecipes = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch('/api/recipes/user', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      console.log('Fetched recipes:', data); // Debugging

      if (response.ok) {
        setUserRecipes(data);
        setFilteredRecipes(data); // Initialize filteredRecipes
      } else {
        setError(data.message || 'Failed to fetch user recipes');
      }
    } catch (error) {
      console.error('Error fetching user recipes:', error);
      setError('Error fetching user recipes');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDeleteRecipe = async () => {
    try {
      const response = await fetch(
        `/api/recipes/delete/${selectedRecipe.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.ok) {
        fetchUserRecipes();
        setSelectedRecipe(null);
      } else {
        alert('Failed to delete recipe');
      }
    } catch (error) {
      console.error('Error deleting recipe:', error);
    }
  };

  const handleCardClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleCloseDetail = () => {
    setSelectedRecipe(null);
  };

  return (
    <div className={styles['addrecipe-container']}>
      <h2 className={styles['section-title']}>Your Recipes</h2>

      <button
        className={styles['addrecipe-button']}
        onClick={() => setShowAddForm(true)}
      >
        Add Recipe
      </button>

      {/* Search Bar */}
      <div className={styles['search-bar']}>
        <input
          type="text"
          className={styles['search-input']}
          placeholder="Search Recipes"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      {/* Add Recipe Form Modal */}
      {showAddForm && (
        <div className={styles['modal-overlay']}>
          <div className={styles['modal-container']}>
            <RecipeForm
              onClose={handleCloseAddRecipe}
              onRecipeAdded={() => {
                fetchUserRecipes();
                setShowAddForm(false);
              }}
            />
          </div>
        </div>
      )}

      {/* Recipe List */}
      {loading ? (
        <div className={styles['spinner']}></div>
      ) : error ? (
        <p className={styles['error-message']}>{error}</p>
      ) : filteredRecipes.length === 0 ? (
        <p className={styles['no-recipes-message']}>No recipes found.</p>
      ) : (
        <ul className={styles['recipe-grid']}>
          {filteredRecipes.map((recipe) => (
            <li key={recipe.id}>
              <RecipeModalMini
                recipe={recipe}
                onClick={() => handleCardClick(recipe)}
              />
            </li>
          ))}
        </ul>
      )}

      {/* Edit Recipe Modal */}
      {selectedRecipe && (
        <div className={styles['modal-overlay']}>
          <div className={styles['modal-container']}>
            <RecipeForm
              recipe={selectedRecipe}
              isEdit={true}
              onClose={handleCloseDetail}
              onRecipeUpdated={() => {
                fetchUserRecipes();
                setSelectedRecipe(null);
              }}
              handleDelete={handleDeleteRecipe}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AddRecipe;
