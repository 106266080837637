import React, { useEffect, useState } from "react";
import Search from "./search";
import styles from "./RecipeDatabase.module.css";

function RecipeDatabase() {
  const [filters, setFilters] = useState([]); // Store filters
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    authenticateAndFetchFilters();
  }, []);

  // Authenticate and fetch filters
  const authenticateAndFetchFilters = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("You are not authenticated. Please log in.");
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    setIsAuthenticated(true);

    try {
      const response = await fetch("/api/users-filters", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch filters.");
      }

      const data = await response.json();
      setFilters(data); // Store filters
    } catch (error) {
      console.error("Error fetching filters:", error.message);
      setError("Error fetching filters. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Add a new filter
  const addFilter = async () => {
    const token = localStorage.getItem("token");
    const newFilter = {
      header_title: "New Filter",
      filters: [],
      component_id: `search${filters.length + 1}`,
    };

    try {
      const response = await fetch("/api/users-filters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newFilter),
      });

      if (!response.ok) {
        throw new Error("Failed to add filter.");
      }

      const addedFilter = await response.json();
      setFilters((prevFilters) => [...prevFilters, addedFilter]);
    } catch (error) {
      console.error("Error adding filter:", error.message);
      alert("Error adding filter. Please try again.");
    }
  };

  // Remove a filter
  const removeFilter = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`/api/users-filters/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete filter.");
      }

      // Remove the filter from the local state
      setFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== id));
    } catch (error) {
      console.error("Error deleting filter:", error.message);
      alert("Error deleting filter. Please try again.");
    }
  };

  // Update filter order
  const updateOrder = async (id, newOrder) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch("/api/update-filter-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, newOrder }),
      });

      if (!response.ok) {
        throw new Error("Failed to update filter order.");
      }

      const { message } = await response.json();
      console.log(message);

      // Optimistically update the order in the local state
      setFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];
        const currentIndex = updatedFilters.findIndex((filter) => filter.id === id);

        if (currentIndex === -1) return updatedFilters; // If filter not found, return unchanged state

        const targetIndex = updatedFilters.findIndex(
          (filter) => filter.order === newOrder
        );

        // Swap the orders
        if (targetIndex !== -1) {
          [updatedFilters[currentIndex].order, updatedFilters[targetIndex].order] = [
            updatedFilters[targetIndex].order,
            updatedFilters[currentIndex].order,
          ];
        }

        // Re-sort filters by their updated order
        return updatedFilters.sort((a, b) => a.order - b.order);
      });
    } catch (error) {
      console.error("Error updating filter order:", error.message);
      alert("Error updating filter order. Please try again.");
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className={styles["recipe-database"]}>
        <h2>Browse</h2>
        <div className={styles["spinner"]}></div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className={styles["recipe-database"]}>
        <h2>Browse</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles["recipe-database"]}>
      <h2>Browse</h2>

      {isAuthenticated ? (
        filters.length > 0 ? (
          [...filters] // Create a copy of the filters array
            .sort((a, b) => a.order - b.order) // Sort by order value
            .map((filter) => (
              <div key={filter.id} className={styles["filter-container"]}>
                <Search componentId={filter.component_id} />

                <div className={styles["button-group"]}>
                  {/* Move Up */}
                  <button
                    onClick={() => updateOrder(filter.id, filter.order - 1)}
                    disabled={filter.order === 1}
                  >
                    Move Up
                  </button>

                  {/* Move Down */}
                  <button
                    onClick={() => updateOrder(filter.id, filter.order + 1)}
                    disabled={filter.order === filters.length}
                  >
                    Move Down
                  </button>

                  {/* Remove */}
                  <button onClick={() => removeFilter(filter.id)}>Remove</button>
                </div>
              </div>
            ))
        ) : (
          <p>No filters available. Add a filter to get started!</p>
        )
      ) : (
        <p>Please log in to access your filters.</p>
      )}

      {/* Add Filter Button */}
      <button onClick={addFilter} className={styles["add-button"]}>
        Add Filter
      </button>
    </div>
  );
}

export default RecipeDatabase;
