import React, { useRef, useState, useEffect } from 'react';
import styles from './RecipeModalMini.module.css';

function RecipeModalMini({
  recipe,
  onClick,
  onGrocerycartChange,
  showAddButton = true,
  showQuantityControls = true,
  selectedCartId,
}) {
  const cardRef = useRef(null);
  const titleRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  // State variables for cart management
  const [userCarts, setUserCarts] = useState([]);
  const [cartsContainingRecipe, setCartsContainingRecipe] = useState([]);
  const [quantities, setQuantities] = useState({});

  // Dropdown state
  const [showCartDropdown, setShowCartDropdown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Fetch user carts on component mount
  useEffect(() => {
    fetchUserCarts();
  }, []);

  // Fetch carts containing the recipe after userCarts are loaded
  useEffect(() => {
    if (recipe && (recipe.recipe_id || recipe.id) && userCarts.length > 0) {
      fetchGrocerycartStatus();
    }
  }, [recipe, userCarts]);

  // Helper function to calculate servings fraction
  const calculateServingFraction = (quantity, servings) => {
    if (!quantity || !servings) return '';
    return `${quantity} / ${servings}`;
  };
  


  const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found for authentication.');
      return null;
    }
    return `Bearer ${token}`;
  };

  const fetchUserCarts = async () => {
    const token = getToken();
    if (!token) return;

    try {
      const response = await fetch('/api/user-carts', {
        headers: { Authorization: token },
      });
      const data = await response.json();

      if (response.ok) {
        setUserCarts(data || []);
      } else {
        console.error('Failed to fetch user carts:', data.message);
      }
    } catch (error) {
      console.error('Error fetching user carts:', error);
    }
  };

  const fetchGrocerycartStatus = async () => {
    const token = getToken();
    if (!token) return;

    try {
      const cartsWithRecipe = [];
      const quantitiesMap = {};

      const recipeId = recipe.recipe_id || recipe.id;

      // Loop over each user cart
      for (const cart of userCarts) {
        const response = await fetch(`/api/grocery-cart?cartId=${cart.id}`, {
          headers: { Authorization: token },
        });

        if (!response.ok) {
          console.error(`Failed to fetch grocery cart for cartId ${cart.id}`);
          continue; // Skip to the next cart
        }

        const data = await response.json();
        const recipes = data.recipes || [];

        recipes.forEach((item) => {
          if (item.recipe_id === recipeId) {
            cartsWithRecipe.push(cart.id);
            quantitiesMap[cart.id] = item.quantity;
          }
        });
      }

      setCartsContainingRecipe(cartsWithRecipe);
      setQuantities(quantitiesMap);
    } catch (error) {
      console.error('Error checking grocery cart status:', error);
    }
  };

  const handleCartSelection = async (cartId, isChecked) => {
    if (isChecked) {
      // Add to cart with default quantity 1
      await addToCart(cartId, 1);
    } else {
      // Remove from cart
      await removeFromCart(cartId);
    }
  };

  const addToCart = async (cartId, quantityToAdd = 1) => {
    const token = getToken();
    if (!token) return;
  
    const recipeId = recipe.recipe_id || recipe.id;
    if (!recipeId) return;
  
    try {
      const response = await fetch('/api/grocery-cart/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          recipe_id: recipeId,
          cart_id: cartId,
          quantity: quantityToAdd, // Save the whole number the user inputs
        }),
      });
  
      if (!response.ok) {
        const data = await response.json();
        alert(data.error || 'Failed to add recipe to grocery cart');
        return;
      }
  
      // Update local state
      setCartsContainingRecipe((prevCarts) => [...new Set([...prevCarts, cartId])]);
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [cartId]: quantityToAdd,
      }));
  
      if (onGrocerycartChange) onGrocerycartChange();
    } catch (error) {
      console.error('Error adding to grocery cart:', error);
    }
  };
  

  const removeFromCart = async (cartId) => {
    const token = getToken();
    if (!token) return;

    const recipeId = recipe.recipe_id || recipe.id;
    if (!recipeId) return;

    try {
      const response = await fetch('/api/grocery-cart/remove', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ recipe_id: recipeId, cart_id: cartId }),
      });

      if (!response.ok) {
        const data = await response.json();
        alert(data.error || 'Failed to remove recipe from grocery cart');
        return;
      }

      // Update local state
      setCartsContainingRecipe((prevCarts) => prevCarts.filter((id) => id !== cartId));
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        delete newQuantities[cartId];
        return newQuantities;
      });

      // Call onGrocerycartChange with recipeId if removed from selected cart
      if (onGrocerycartChange) {
        if (cartId === selectedCartId) {
          onGrocerycartChange(recipeId);
        } else {
          onGrocerycartChange();
        }
      }
    } catch (error) {
      console.error('Error removing from grocery cart:', error);
    }
  };

  const updateQuantity = async (cartId, newQuantity) => {
    const token = getToken();
    if (!token) return;
  
    const recipeId = recipe.recipe_id || recipe.id;
    if (!recipeId) return;
  
    if (newQuantity < 1) {
      // Remove from cart if quantity is less than 1
      await removeFromCart(cartId);
      return;
    }
  
    try {
      const response = await fetch('/api/grocery-cart/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          recipe_id: recipeId,
          cart_id: cartId,
          quantity: newQuantity, // Save the whole number
        }),
      });
  
      if (!response.ok) {
        const data = await response.json();
        alert(data.error || 'Failed to update recipe quantity');
        return;
      }
  
      // Update local state
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [cartId]: newQuantity,
      }));
  
      if (onGrocerycartChange) onGrocerycartChange();
    } catch (error) {
      console.error('Error updating grocery cart quantity:', error);
    }
  };
  
  

  const handleMouseEnter = () => {
    if (titleRef.current && cardRef.current) {
      const titleWidth = titleRef.current.offsetWidth;
      const containerWidth = cardRef.current.offsetWidth;

      if (titleWidth > containerWidth) {
        setScrolling(true);
      }
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setScrolling(false);
    setIsHovered(false);
    // Do not close the dropdown here
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowCartDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles['recipe-mini-card']}
      onClick={() => onClick(recipe)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
    >
      {cartsContainingRecipe.length > 0 && (
        <div className={styles['grocery-cart-indicator']}></div>
      )}
  
      {/* Hover Controls Container */}
      <div className={styles['hover-controls']}>
        {/* Edit Button */}
        {showAddButton && isHovered && (
          <button
            className={styles['edit-carts-button']}
            onClick={(e) => {
              e.stopPropagation();
              setShowCartDropdown(!showCartDropdown);
            }}
          >
            Add to Cart
          </button>
        )}
  
        {showQuantityControls && cartsContainingRecipe.includes(selectedCartId) && (
        <div className={styles['quantity-controls']}>
          <div className={styles['cart-quantity-control']}>
            {/* Decrease Quantity Button */}
            <button
              className={styles['decrease-quantity-button']}
              onClick={(e) => {
                e.stopPropagation();
                const newQuantity = quantities[selectedCartId] - 1;
                updateQuantity(selectedCartId, Math.max(newQuantity, 1)); // Ensure it doesn't go below 1
              }}
            >
              -
            </button>

            {/* Quantity Input and Fraction Container */}
            <div className={styles['quantity-container']}>
              <input
                type="number"
                className={styles['quantity-input']}
                value={quantities[selectedCartId]}
                min="1"
                onClick={(e) => e.stopPropagation()} // Prevent propagation to avoid modal closing
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value, 10);
                  if (newQuantity >= 1) {
                    updateQuantity(selectedCartId, newQuantity);
                  }
                }}
              />
              <span className={styles['serving-fraction']}>
                {` / ${recipe.servings}`}
              </span>
            </div>

            {/* Increase Quantity Button */}
            <button
              className={styles['increase-quantity-button']}
              onClick={(e) => {
                e.stopPropagation();
                updateQuantity(selectedCartId, quantities[selectedCartId] + 1);
              }}
            >
              +
            </button>
          </div>
        </div>
      )}


  
        {/* Remove from Cart Button */}
        {showQuantityControls && cartsContainingRecipe.includes(selectedCartId) && (
          <button
            className={styles['remove-from-cart-button']}
            onClick={(e) => {
              e.stopPropagation();
              removeFromCart(selectedCartId);
            }}
          >
            Remove from Cart
          </button>
        )}
      </div>
  
      {/* Title */}
      <div className={styles['title-container']}>
        <h3
          ref={titleRef}
          className={`${styles['recipe-title']} ${scrolling ? styles['scrolling'] : ''}`}
        >
          {recipe.title}
        </h3>
      </div>
  
      {/* Image */}
      {recipe.image_url && (
        <img src={recipe.image_url} alt={recipe.title} className={styles['recipe-image']} />
      )}
  
      {/* Recipe Info */}
      <div className={styles['recipe-info-section']}>
        {recipe.total_time_min && (
          <p className={styles['recipe-info']}>
            {(() => {
              const hours = Math.floor(recipe.total_time_min / 60);
              const minutes = recipe.total_time_min % 60;
              return (
                <>
                  {hours > 0 && <strong>{hours} hrs </strong>}
                  {minutes > 0 && <strong>{minutes} min</strong>}
                </>
              );
            })()}
          </p>
        )}
        {recipe.calories && (
          <p className={styles['recipe-info']}>{recipe.calories} Calories</p>
        )}
      </div>
  
      {/* Cart Dropdown Overlay */}
      {showCartDropdown && (
        <div
          className={styles['cart-dropdown-overlay']}
          onClick={(e) => e.stopPropagation()}
        >
          <ul className={styles['cart-list']}>
            {userCarts.map((cart) => (
              <li key={cart.id} className={styles['cart-item']}>
                <input
                  type="checkbox"
                  id={`cart-${cart.id}`}
                  checked={cartsContainingRecipe.includes(cart.id)}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleCartSelection(cart.id, e.target.checked);
                  }}
                />
                <label htmlFor={`cart-${cart.id}`}>{cart.name}</label>
              </li>
            ))}
          </ul>
        </div>
      )}
  
      {/* Tags */}
      <div className={styles['tags-container']}>
        {recipe.tags &&
          recipe.tags.map((tag, index) => (
            <span key={index} className={styles['tag-block']}>
              {tag}
            </span>
          ))}
      </div>
    </div>
  );
  
  
}

export default RecipeModalMini;
