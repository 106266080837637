import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";

function Home() {
  const navigate = useNavigate();

  return (
    <div className={styles["home-container"]}>
      <div className={styles["welcome-section"]}>
        <h1>Welcome to Kinetic Harvest</h1>
        <p>Simplify your life</p>
        <div className={styles["button-group"]}>
          <button
            className={styles["login-button"]}
            onClick={() => navigate("/login")}
            aria-label="Log in to your account"
          >
            Log In
          </button>
          <button
            className={styles["signup-button"]}
            onClick={() => navigate("/signup")}
            aria-label="Sign up for a new account"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
