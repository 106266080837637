import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Signup successful! You can now log in.");
        navigate("/login");
      } else {
        alert(data.error || data.message || "Signup failed.");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className={styles["signup-container"]}>
      <div className={styles["signup-header"]}>
        <h1>Create an Account</h1>
        <p>Join our community</p>
      </div>
      <form className={styles["signup-form"]} onSubmit={handleSignup}>
        <div className={styles["form-group"]}>
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email address"
            required
          />
        </div>
        <div className={styles["form-group"]}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
            required
          />
        </div>
        <button className={styles["signup-button"]} type="submit">
          Sign Up
        </button>
      </form>
      <div className={styles["footer"]}>
        <p>
          Already have an account?{" "}
          <button
            className={styles["login-link"]}
            onClick={() => navigate("/login")}
            aria-label="Log in to your account"
          >
            Log In
          </button>
        </p>
      </div>
    </div>
  );
}

export default Signup;
