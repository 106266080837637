import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        if (data.token) {
          localStorage.setItem("token", data.token);
          navigate("/dashboard/recipes");
        } else {
          alert("Login successful but no token received.");
        }
      } else {
        alert(data.message || "Login failed.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className={styles["login-container"]}>
      <div className={styles["login-header"]}>
        <h1>Welcome Back!</h1>
        <p>Please log in to continue</p>
      </div>
      <form className={styles["login-form"]} onSubmit={handleLogin}>
        <div className={styles["form-group"]}>
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email address"
            required
          />
        </div>
        <div className={styles["form-group"]}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
            required
          />
        </div>
        <button className={styles["login-button"]} type="submit">
          Log In
        </button>
      </form>
      <div className={styles["footer"]}>
        <p>
          Don’t have an account?{" "}
          <button
            className={styles["signup-link"]}
            onClick={() => navigate("/signup")}
            aria-label="Sign up for a new account"
          >
            Sign Up
          </button>
        </p>
      </div>
    </div>
  );
}

export default Login;
