import React, { useEffect, useState } from "react";
import styles from "./UserMetrics.module.css";

function UserMetrics() {
  // State for user inputs
  const [name, setName] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [targetWeight, setTargetWeight] = useState("");
  const [strategy, setStrategy] = useState("passive");

  // State for calculated metrics
  const [bmi, setBmi] = useState("");
  const [bmr, setBmr] = useState("");
  const [maintenanceCalories, setMaintenanceCalories] = useState("");
  const [caloriesToChangeWeight, setCaloriesToChangeWeight] = useState({});
  const [timeToGoal, setTimeToGoal] = useState("");

  // Calculation details
  const [calculationDetails, setCalculationDetails] = useState("");

  // Fetch user metrics on component mount
  useEffect(() => {
    fetchMetrics();
  }, []);

  // Fetch metrics from backend
  const fetchMetrics = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("You are not authenticated. Please log in.");
      return;
    }

    try {
      const response = await fetch("/api/metrics", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 404) {
        alert("No metrics found. Please enter your information.");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch user metrics.");
      }

      const data = await response.json();
      setName(data.name || "");
      setHeight(data.height || "");
      setWeight(data.weight || "");
      setGender(data.gender || "");
      setActivityLevel(data.activity_level || "");
    } catch (error) {
      console.error("Error fetching metrics:", error.message);
      alert("Error fetching metrics. Please try again.");
    }
  };

  // Calculate metrics
  const calculateMetrics = () => {
    if (!weight || !height || !gender || !activityLevel) return;

    const calculatedBmi = (weight / (height * height)) * 703;
    const calculatedBmr =
      gender === "male"
        ? 66 + 6.23 * weight + 12.7 * height - 6.8 * 30 // Example: assuming age 30
        : 655 + 4.35 * weight + 4.7 * height - 4.7 * 30;

    const activityFactors = {
      sedentary: 1.2,
      "lightly-active": 1.375,
      "moderately-active": 1.55,
      "very-active": 1.725,
    };

    const calculatedMaintenanceCalories = calculatedBmr * (activityFactors[activityLevel] || 1.2);
    setBmi(calculatedBmi.toFixed(1));
    setBmr(Math.round(calculatedBmr));
    setMaintenanceCalories(Math.round(calculatedMaintenanceCalories));

    if (targetWeight) {
      calculateWeightChange(calculatedMaintenanceCalories);
    }

    setCalculationDetails(`
      BMI Calculation:
        BMI = (Weight in lbs / (Height in inches)^2) * 703
        BMI = (${weight} / (${height}^2)) * 703 = ${calculatedBmi.toFixed(1)}

      BMR Calculation:
        Gender: ${gender}
        BMR = ${
          gender === "male"
            ? `66 + (6.23 * Weight in lbs) + (12.7 * Height in inches) - (6.8 * Age)`
            : `655 + (4.35 * Weight in lbs) + (4.7 * Height in inches) - (4.7 * Age)`
        }
        BMR = ${Math.round(calculatedBmr)}

      Maintenance Calories:
        Maintenance = BMR * Activity Factor (${activityFactors[activityLevel]})
        Maintenance = ${Math.round(calculatedBmr)} * ${activityFactors[activityLevel]} = ${Math.round(
      calculatedMaintenanceCalories
    )}
    `);
  };

  // Calculate weight change
  const calculateWeightChange = (calculatedMaintenanceCalories) => {
    const weightDifference = Math.abs(targetWeight - weight);
    const caloriesPerPound = 3500;

    const adjustmentPassive = 250;
    const adjustmentAggressive = 750;

    const isGainWeight = targetWeight > weight;

    const caloriesPassive = isGainWeight
      ? calculatedMaintenanceCalories + adjustmentPassive
      : calculatedMaintenanceCalories - adjustmentPassive;

    const caloriesAggressive = isGainWeight
      ? calculatedMaintenanceCalories + adjustmentAggressive
      : calculatedMaintenanceCalories - adjustmentAggressive;

    const daysToGoalPassive = (weightDifference * caloriesPerPound) / adjustmentPassive;
    const daysToGoalAggressive = (weightDifference * caloriesPerPound) / adjustmentAggressive;

    setCaloriesToChangeWeight({
      passive: Math.round(caloriesPassive),
      aggressive: Math.round(caloriesAggressive),
    });

    setTimeToGoal(
      strategy === "passive"
        ? (daysToGoalPassive / 7).toFixed(1)
        : (daysToGoalAggressive / 7).toFixed(1)
    );

    setCalculationDetails((prevDetails) => `
      ${prevDetails}

      Target Weight Details:
        Target Weight: ${targetWeight} lbs
        Weight Change Strategy: ${strategy === "passive" ? "Passive" : "Aggressive"} 
        Time to Reach Goal: ${timeToGoal} weeks
    `);
  };

  // Save metrics to backend
  const handleSaveMetrics = async (e) => {
    e.preventDefault();

    if (!name || !height || !weight || !gender || !activityLevel) {
      alert("Please fill in all fields.");
      return;
    }

    const payload = {
      name,
      height: parseFloat(height),
      weight: parseFloat(weight),
      gender,
      activityLevel,
    };

    const token = localStorage.getItem("token");

    if (!token) {
      alert("You are not authenticated. Please log in.");
      return;
    }

    try {
      const response = await fetch("/api/metrics", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save user metrics.");
      }

      alert("Metrics saved successfully!");
    } catch (error) {
      console.error("Error saving metrics:", error.message);
      alert("Error saving metrics. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSaveMetrics} className={styles["user-metrics-form"]}>
      <h2>User Metrics</h2>

      {/* Input fields */}
      <div className={styles["user-metrics-group"]}>
        <label htmlFor="name">Name:</label>
        <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className={styles["user-metrics-group"]}>
        <label htmlFor="height">Height (inches):</label>
        <input
          id="height"
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>

      <div className={styles["user-metrics-group"]}>
        <label htmlFor="weight">Weight (lbs):</label>
        <input
          id="weight"
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
      </div>

      <div className={styles["user-metrics-group"]}>
        <label htmlFor="gender">Gender:</label>
        <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>

      <div className={styles["user-metrics-group"]}>
        <label htmlFor="activityLevel">Activity Level:</label>
        <select
          id="activityLevel"
          value={activityLevel}
          onChange={(e) => setActivityLevel(e.target.value)}
        >
          <option value="">Select Activity Level</option>
          <option value="sedentary">Sedentary</option>
          <option value="lightly-active">Lightly Active</option>
          <option value="moderately-active">Moderately Active</option>
          <option value="very-active">Very Active</option>
        </select>
      </div>

      <div className={styles["user-metrics-group"]}>
        <label htmlFor="targetWeight">Target Weight (lbs):</label>
        <input
          id="targetWeight"
          type="number"
          value={targetWeight}
          onChange={(e) => setTargetWeight(e.target.value)}
        />
      </div>

      <div className={styles["user-metrics-group"]}>
        <label>Strategy:</label>
        <select value={strategy} onChange={(e) => setStrategy(e.target.value)}>
          <option value="passive">Passive</option>
          <option value="aggressive">Aggressive</option>
        </select>
      </div>

      {/* Calculated metrics */}
      <h3>Calculated Metrics</h3>
      <p>
        <strong>BMI:</strong> {bmi}
      </p>
      <p>
        <strong>BMR (Calories burned at rest):</strong> {bmr}
      </p>
      <p>
        <strong>Maintenance Calories:</strong> {maintenanceCalories}
      </p>
      <p>
        <strong>Calories to {targetWeight > weight ? "Gain" : "Lose"} Weight:</strong>
        <br />
        Passive: {caloriesToChangeWeight.passive || "N/A"} <br />
        Aggressive: {caloriesToChangeWeight.aggressive || "N/A"}
      </p>
      <p>
        <strong>Time to Reach Target Weight:</strong> {timeToGoal} weeks
      </p>

      {/* Calculation details */}
      <details>
        <summary>Calculation Details</summary>
        <pre>{calculationDetails}</pre>
      </details>

      <button type="button" onClick={calculateMetrics} className={styles["calculate-button"]}>
        Recalculate
      </button>
      <button type="submit" className={styles["save-button"]}>
        Save
      </button>
    </form>
  );
}

export default UserMetrics;
