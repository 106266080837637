import React, { useEffect, useState } from "react";
import styles from "./Journal.module.css";

function Journal() {
  // State for journal entries and user authentication
  const [journalEntries, setJournalEntries] = useState([]);
  const [entry, setEntry] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // Default to today
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Fetch journal entries on component mount
  useEffect(() => {
    authenticateAndFetchEntries();
  }, []);

  // Authenticate and fetch journal entries
  const authenticateAndFetchEntries = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("You are not authenticated. Please log in.");
      return;
    }

    setIsAuthenticated(true);

    try {
      const response = await fetch("/api/journal", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch journal entries.");
      }

      const data = await response.json();
      setJournalEntries(data.entries || []);
    } catch (error) {
      console.error("Error fetching journal entries:", error.message);
      alert("Error fetching journal entries. Please try again.");
    }
  };

  // Handle saving a new journal entry
  const handleSaveEntry = async (e) => {
    e.preventDefault();

    if (!entry.trim()) {
      alert("Journal entry cannot be empty.");
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      alert("You are not authenticated. Please log in.");
      return;
    }

    const payload = { date, entry };

    try {
      const response = await fetch("/api/journal", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save journal entry.");
      }

      alert("Journal entry saved successfully!");
      setEntry(""); // Clear input
      authenticateAndFetchEntries(); // Refresh entries
    } catch (error) {
      console.error("Error saving journal entry:", error.message);
      alert("Error saving journal entry. Please try again.");
    }
  };

  return (
    <div className={styles["journal-container"]}>
      <h2>Daily Journal</h2>

      {isAuthenticated ? (
        <>
          {/* Form to add a new journal entry */}
          <form onSubmit={handleSaveEntry} className={styles["journal-form"]}>
            <div className={styles["journal-group"]}>
              <label htmlFor="date">Date:</label>
              <input
                id="date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className={styles["journal-group"]}>
              <label htmlFor="entry">Journal Entry:</label>
              <textarea
                id="entry"
                value={entry}
                onChange={(e) => setEntry(e.target.value)}
                placeholder="Write your thoughts here..."
              />
            </div>

            <button type="submit" className={styles["save-button"]}>
              Save Entry
            </button>
          </form>

          {/* Display list of previous journal entries */}
          <h3>Previous Entries</h3>
          {journalEntries.length > 0 ? (
            <ul className={styles["journal-entries"]}>
              {journalEntries.map((entry, index) => (
                <li key={index} className={styles["journal-entry"]}>
                  <strong>{entry.date}</strong>
                  <p>{entry.entry}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No entries yet. Start journaling today!</p>
          )}
        </>
      ) : (
        <p>Please log in to access your journal.</p>
      )}
    </div>
  );
}

export default Journal;
