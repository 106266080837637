import React from "react";
import { Link, Outlet } from "react-router-dom";
import styles from "./Dashboard.module.css";

function Dashboard() {
  return (
    <div className={styles["dashboard-container"]}>
      {/* Navigation Bar */}
      <header className={styles["navbar"]}>
        <div className={styles["logo"]}>
          <Link to="/" className={styles["brand"]}>
            Kinetic Harvest
          </Link>
        </div>
        <nav className={styles["nav-links"]}>
       {/*   <Link to="/dashboard" className={styles["nav-item"]}>
            Dashboard
          </Link>  */}
          <Link to="/dashboard/recipes" className={styles["nav-item"]}>
            Browse
          </Link>
          <Link to="/dashboard/grocery-plan" className={styles["nav-item"]}>
            Grocery List
          </Link>
          <Link to="/dashboard/add-recipe" className={styles["nav-item"]}>
            Your Recipes
          </Link>
          <Link to="/dashboard/your-kitchen" className={styles["nav-item"]}>
            Your Kitchen
          </Link>
          <Link to="/dashboard/user-metrics" className={styles["nav-item"]}>
            Metrics
          </Link>
          <Link to="/dashboard/journal" className={styles["nav-item"]}>
            Journal
          </Link>
        </nav>
      </header>

      {/* Main Content */}
      <main className={styles["dashboard-content"]}>
        <Outlet />
      </main>
    </div>
  );
}

export default Dashboard;
