import React, { useState } from 'react';

function ImageUploader({ onUpload }) {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const uploadImage = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file); // Match the 'image' field expected by the backend

    setLoading(true);

    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      const response = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Include the JWT for authentication
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      setImageUrl(data.url); // Set the uploaded image URL
      if (onUpload) onUpload(data.url); // Pass the URL to parent if provided
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={uploadImage} />
      {loading && <p>Uploading...</p>}
      {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '300px' }} />}
    </div>
  );
}

export default ImageUploader;
