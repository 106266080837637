import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styles from "./App.module.css"; // Import module CSS
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import RecipeDatabase from "./components/Recipe_database";
import YourKitchen from "./components/YourKitchen";
import AddRecipe from "./components/AddRecipe";
import GroceryPlan from "./components/Grocery_plan";
import ImageUploader from "./components/ImageUploader"; // Import ImageUploader
import UserMetrics from "./components/UserMetrics"; // Import User Metrics
import Journal from "./components/Journal";


function App() {
  return (
    <div className={styles["app-container"]}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Protected Dashboard with Nested Routes */}
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            {/* Nested Routes inside Dashboard */}
            <Route path="" element={<div>Welcome to your Dashboard!</div>} />
            <Route path="recipes" element={<RecipeDatabase />} />
            <Route path="add-recipe" element={<AddRecipe />} />
            <Route path="your-kitchen" element={<YourKitchen />} />
            <Route path="grocery-plan" element={<GroceryPlan />} />
            <Route path="user-metrics" element={<UserMetrics />} />
            <Route path="journal" element={<Journal />} />
          </Route>

          {/* Standalone route for testing ImageUploader */}
          <Route path="/upload-image" element={<ImageUploader />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
