import React, { useState, useEffect } from 'react';
import RecipeModalMini from './RecipeModalMini';
import RecipeModal from './RecipeModal';
import CombinedIngredientsModal from './CombinedIngredientsModal';
import styles from './GroceryPlan.module.css';

function GroceryPlan() {
  // State variables
  const [grocerycartRecipes, setGrocerycartRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [error, setError] = useState(null);
  const [combinedIngredients, setCombinedIngredients] = useState([]);
  const [isCombinedIngredientsModalOpen, setCombinedIngredientsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false); // Ensure loading state starts as false
  const [selectedCartId, setSelectedCartId] = useState(null);
  const [userCarts, setUserCarts] = useState([]);
  const [newCartName, setNewCartName] = useState(''); // Input for new cart name
  const [isCreatingCart, setIsCreatingCart] = useState(false); // Loading state for cart creation
  const [showCartModal, setShowCartModal] = useState(false);


  // Fetch user carts on component mount
  useEffect(() => {
    fetchUserCarts();
  }, []);

  // Fetch grocery cart recipes when selectedCartId changes
  useEffect(() => {
    if (selectedCartId) {
      fetchGrocerycartRecipes(selectedCartId);
    }
  }, [selectedCartId]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = grocerycartRecipes.filter((recipe) =>
        recipe.title && recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredRecipes(filtered);
    } else {
      setFilteredRecipes(grocerycartRecipes);
    }
  }, [searchQuery, grocerycartRecipes]);

  const fetchUserCarts = async () => {
    try {
      const response = await fetch('/api/user-carts', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setUserCarts(data || []); // Ensure data is an array
        if (data.length > 0) {
          setSelectedCartId(data[0].id); // Select the first cart by default
        }
      } else {
        setUserCarts([]); // Default to empty array on failure
        setError(data.message || 'Failed to fetch user carts');
      }
    } catch (error) {
      console.error('Error fetching user carts:', error);
      setUserCarts([]); // Default to empty array on failure
      setError('Error fetching user carts');
    }
  };

  const fetchGrocerycartRecipes = async (cartId) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/grocery-cart?cartId=${cartId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setGrocerycartRecipes(data.recipes || []); // Ensure recipes is an array
        setFilteredRecipes(data.recipes || []);
      } else {
        setError(data.message || 'Failed to fetch grocery cart recipes');
        setGrocerycartRecipes([]); // Ensure cart appears empty on failure
      }
    } catch (error) {
      console.error('Error fetching grocery cart recipes:', error);
      setError('Error fetching grocery cart recipes');
      setGrocerycartRecipes([]);
    } finally {
      setLoading(false);
    }
  };

  const handleGrocerycartChange = (removedRecipeId) => {
    setGrocerycartRecipes((prevRecipes) =>
      prevRecipes.filter((recipe) => recipe.recipe_id !== removedRecipeId)
    );
  };

  const fetchCombinedIngredients = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found for authentication.');
      return;
    }

    try {
      const response = await fetch(`/api/grocery-cart/combined-ingredients?cartId=${selectedCartId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch combined ingredients');
      }

      const data = await response.json();
      setCombinedIngredients(data);
      setCombinedIngredientsModalOpen(true);
    } catch (error) {
      console.error('Error fetching combined ingredients:', error);
      alert('Failed to fetch combined ingredients.');
    }
  };

  const closeCombinedIngredientsModal = () => {
    setCombinedIngredientsModalOpen(false);
  };

  const openRecipeModal = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const closeRecipeModal = () => {
    setSelectedRecipe(null);
  };

  const handleAddCart = async () => {
    if (!newCartName.trim()) {
      alert('Cart name cannot be empty.');
      return;
    }
    setIsCreatingCart(true);
    try {
      const response = await fetch('/api/user-carts/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ name: newCartName }),
      });
      const data = await response.json();
      if (response.ok) {
        setUserCarts((prevCarts) => [...prevCarts, data]);
        setSelectedCartId(data.id); // Automatically select the new cart
        setNewCartName(''); // Clear the input field
      } else {
        alert(data.error || 'Failed to add cart.');
      }
    } catch (error) {
      console.error('Error adding cart:', error);
      alert('Failed to add cart.');
    } finally {
      setIsCreatingCart(false);
    }
  };

  const handleDeleteCart = async () => {
    if (!selectedCartId) {
      alert('No cart selected.');
      return;
    }

    const confirmDelete = window.confirm('Are you sure you want to delete this cart?');
    if (!confirmDelete) return;

    try {
      const response = await fetch('/api/user-carts/remove', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ cart_id: selectedCartId }),
      });

      if (response.ok) {
        setUserCarts((prevCarts) => {
          // Filter out the deleted cart
          const updatedCarts = prevCarts.filter((cart) => cart.id !== selectedCartId);
          if (updatedCarts.length > 0) {
            // Automatically select the previous cart in the list
            setSelectedCartId(updatedCarts[0].id);
          } else {
            // If no carts are left, clear the selectedCartId and recipes
            setSelectedCartId(null);
            setGrocerycartRecipes([]);
            setFilteredRecipes([]);
          }
          return updatedCarts;
        });
      } else {
        alert('Failed to delete cart.');
      }
    } catch (error) {
      console.error('Error deleting cart:', error);
      alert('Failed to delete cart.');
    }
  };

  return (
    <div className={styles['grocery-plan-container']}>


      {error && <p className={styles['error']}>{error}</p>} {/* Error display */}

      {/* Cart Selector */}
      <div className={styles['cart-selector']}>
        <h2
          className={styles['cart-title']}
          onClick={() => setShowCartModal((prev) => !prev)}
        >
          {userCarts.find((cart) => cart.id === selectedCartId)?.name || 'Select Cart'}
        </h2>

        
        {showCartModal && (
          <div className={styles['cart-modal']}>
            {userCarts.map((cart) => (
              <p
                key={cart.id}
                className={styles['cart-option']}
                onClick={() => {
                  setSelectedCartId(cart.id);
                  setShowCartModal(false);
                }}
              >
                {cart.name}
              </p>
            ))}
          </div>
        )}

        {/* Delete Cart Button */}
        <button onClick={handleDeleteCart} className={styles['delete-cart-button']}>
          Delete Cart
        </button>


      </div>



      {/* Add New Cart */}
      <div className={styles['add-cart-container']}>
        <input
          type="text"
          placeholder="New cart name"
          value={newCartName}
          onChange={(e) => setNewCartName(e.target.value)}
          className={styles['add-cart-input']}
        />
        <button
          onClick={handleAddCart}
          className={styles['add-cart-button']}
          disabled={isCreatingCart}
        >
          {isCreatingCart ? 'Adding...' : 'Add Cart'}
        </button>
      </div>


              {/* Combined Ingredients Button */}
              <button
          onClick={fetchCombinedIngredients}
          className={styles['fetch-ingredients-button']}
          disabled={!selectedCartId}
        >
          Generate Shopping List
        </button>

        {/* Combined Ingredients Modal */}
        {isCombinedIngredientsModalOpen && (
          <CombinedIngredientsModal
            ingredients={combinedIngredients}
            onClose={closeCombinedIngredientsModal}
          />
        )}



      {/* Search Bar */}
      <div className={styles['search-bar-container']}>
        <input
          type="text"
          className={styles['recipe-search-input']}
          placeholder="Search recipes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Grocery Content */}
      <div className={styles['grocery-content']}>
        {loading ? (
          <div className={styles['spinner']}></div>
        ) : grocerycartRecipes.length === 0 ? (
          <p className={styles['no-recipes-message']}>No recipes in your current cart.</p>
        ) : filteredRecipes.length > 0 ? (
          <ul className={styles['recipe-mini-cart']}>
            {filteredRecipes.map((recipe) => (
              <li key={recipe.recipe_id}>
                <RecipeModalMini
                  recipe={recipe}
                  onClick={() => openRecipeModal(recipe)}
                  onGrocerycartChange={handleGrocerycartChange}
                  selectedCartId={selectedCartId} // New prop
                  showAddButton={true}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles['no-recipes-message']}>No recipes match your search.</p>
        )}

        {selectedRecipe && (
          <div className={styles['recipe-modal-container']}>
            <RecipeModal recipe={selectedRecipe} onClose={closeRecipeModal} />
          </div>
        )}
      </div>
    </div>
  );
}

export default GroceryPlan;
