import React, { useEffect, useState, useRef } from 'react';
import RecipeModal from './RecipeModal';
import RecipeModalMini from './RecipeModalMini';
import styles from './Search.module.css';

function Search({ componentId }) {
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const recipesPerPage = 10;

  // State variables for search and filters
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);

  // State to manage dynamic filters, initialized as null
  const [activeFilters, setActiveFilters] = useState(null);

  // State for header title
  const [headerTitle, setHeaderTitle] = useState('Recipes');
  const [modalHeaderTitle, setModalHeaderTitle] = useState('Recipes');

  // State for filter preset (only one per component)
  const [filterPreset, setFilterPreset] = useState(null);

  // Loading state for fetching additional pages
  const [isFetching, setIsFetching] = useState(false);

  // Reference to the scrollable container
  const scrollContainerRef = useRef(null);

  // Available filter options
  const filterOptions = [
    { value: 'maxMissingIngredients', label: 'Max Missing Ingredients' },
    { value: 'filterByEquipment', label: 'Only Recipes with My Equipment' },
    { value: 'filterByGroceryList', label: 'Only Recipes in My Grocery List' },
    { value: 'ingredients', label: 'Ingredients' },
    { value: 'equipment', label: 'Equipment' },
    { value: 'calories', label: 'Calories' },
    { value: 'fat', label: 'Fat' },
    { value: 'sat_fat', label: 'Saturated Fat' },
    { value: 'carbs', label: 'Carbohydrates' },
    { value: 'fiber', label: 'Fiber' },
    { value: 'sugar', label: 'Sugar' },
    { value: 'protein', label: 'Protein' },
    { value: 'prep_time_min', label: 'Prep Time (min)' },
    { value: 'cook_time_min', label: 'Cook Time (min)' },
    { value: 'total_time_min', label: 'Total Time (min)' },
    { value: 'servings', label: 'Servings' },
    { value: 'data_source', label: 'Data Source' },
    { value: 'data_source_name', label: 'Data Source Name' },
    { value: 'tags', label: 'Tags' },
  ];

  useEffect(() => {
    fetchFilterPreset();
  }, []);

  useEffect(() => {
    if (activeFilters !== null) {
      fetchRecipes();
    }
  }, [currentPage, activeFilters]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const handleScroll = () => {
      if (
        scrollContainer.scrollLeft + scrollContainer.clientWidth >=
        scrollContainer.scrollWidth - 5
      ) {
        if (!isFetching && currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [isFetching, currentPage, totalPages]);

  useEffect(() => {
    if (searchTerm) {
      const searchedRecipes = recipes.filter((recipe) =>
        recipe.title && recipe.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredRecipes(searchedRecipes);
    } else {
      setFilteredRecipes(recipes);
    }
  }, [searchTerm, recipes]);

  const fetchRecipes = async () => {
    const token = localStorage.getItem('token');
    const offset = (currentPage - 1) * recipesPerPage;

    const queryParams = new URLSearchParams();
    queryParams.append('limit', recipesPerPage);
    queryParams.append('offset', offset);

    if (activeFilters && activeFilters.length > 0) {
      activeFilters.forEach((filter) => {
        switch (filter.filterType) {
          case 'maxMissingIngredients':
            if (filter.value) {
              queryParams.append('maxMissingIngredients', filter.value);
            }
            break;
          case 'filterByGroceryList':
            if (filter.value) {
              queryParams.append('filterByGroceryList', filter.value);
            }
            break;
          case 'filterByEquipment':
            if (filter.value) {
              queryParams.append('filterByEquipment', filter.value);
            }
            break;
          case 'ingredients':
            if (filter.value) {
              queryParams.append('filterIngredients', filter.value);
            }
            break;
          case 'equipment':
            if (filter.value) {
              queryParams.append('filterEquipment', filter.value);
            }
            break;
          case 'calories':
          case 'fat':
          case 'sat_fat':
          case 'carbs':
          case 'fiber':
          case 'sugar':
          case 'protein':
          case 'prep_time_min':
          case 'cook_time_min':
          case 'total_time_min':
          case 'servings':
            if (filter.operator && filter.value) {
              const operatorMap = {
                gte: 'min',
                lte: 'max',
                gt: 'gt',
                lt: 'lt',
                eq: 'eq',
              };
              const queryKey = `${operatorMap[filter.operator]}_${filter.filterType}`;
              queryParams.append(queryKey, filter.value);
            }
            break;
          case 'data_source':
          case 'data_source_name':
            if (filter.value) {
              queryParams.append(filter.filterType, filter.value);
            }
            break;
          case 'tags':
            if (filter.value) {
              queryParams.append('tags', filter.value);
            }
            break;
          default:
            break;
        }
      });
    }

    if (isFetching) {
      console.log('Fetch already in progress. Skipping duplicate request.');
      return;
    }

    setIsFetching(true);

    try {
      const response = await fetch(`/api/recipes?${queryParams.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch recipes');
      }

      const data = await response.json();

      const uniqueRecipes = Array.from(
        new Map(data.recipes.map((recipe) => [recipe.recipe_id, recipe])).values()
      );

      setRecipes(uniqueRecipes);
      setFilteredRecipes(uniqueRecipes);

      setTotalPages(Math.ceil(data.total / recipesPerPage));
      setError(null);
    } catch (error) {
      console.error('Error fetching recipes:', error);
      setError('Could not load recipes.');
    } finally {
      setIsFetching(false);
    }
  };

  const fetchFilterPreset = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`/api/filter-presets/${componentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok && data) {
        setFilterPreset(data);
        setHeaderTitle(data.header_title);
        setActiveFilters(data.filters);
        setModalHeaderTitle(data.header_title);
      } else {
        setFilterPreset(null);
        setActiveFilters([]);
        setHeaderTitle('Recipes');
        setModalHeaderTitle('Recipes');
      }
    } catch (error) {
      console.error('Error fetching filter preset:', error);
    }
  };

  const saveFilterPreset = async () => {
    const token = localStorage.getItem('token');

    const preset = {
      component_id: componentId,
      header_title: modalHeaderTitle,
      filters: activeFilters.map((filter, index) => ({
        ...filter,
        order: filter.order ?? index + 1,
      })),
    };

    try {
      const response = await fetch('/api/filter-presets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(preset),
      });

      const data = await response.json();
      if (response.ok) {
        setFilterPreset(data.data);
        setHeaderTitle(modalHeaderTitle);
        setShowFilterModal(false);
        setCurrentPage(1);
        setRecipes([]);
        fetchRecipes();
      } else {
        alert(data.error || 'Failed to save filter preset');
      }
    } catch (error) {
      console.error('Error saving filter preset:', error);
      alert('Failed to save filter preset.');
    }
  };

  const deleteFilterPreset = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`/api/filter-presets/${componentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setFilterPreset(null);
        setHeaderTitle('Recipes');
        setActiveFilters([]);
        setModalHeaderTitle('Recipes');
        setCurrentPage(1);
        setRecipes([]);
        fetchRecipes();
      } else {
        alert(data.error || 'Failed to delete filter preset');
      }
    } catch (error) {
      console.error('Error deleting filter preset:', error);
      alert('Failed to delete filter preset.');
    }
  };

  const handleCardClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleCloseModal = () => {
    setSelectedRecipe(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleFilterModal = () => {
    setModalHeaderTitle(headerTitle);
    setShowFilterModal(!showFilterModal);
  };

  const addFilter = () => {
    setActiveFilters((prevFilters) => [
      ...(prevFilters || []),
      { filterType: '', operator: '', value: '', checked: false },
    ]);
  };

  const handleFilterTypeChange = (index, event) => {
    const newFilters = [...(activeFilters || [])];
    newFilters[index].filterType = event.target.value;
    newFilters[index].operator = '';
    newFilters[index].value = '';
    newFilters[index].checked = false;
    setActiveFilters(newFilters);
  };

  const handleFilterValueChange = (index, event) => {
    const newFilters = [...(activeFilters || [])];
    newFilters[index].value = event.target.value;
    setActiveFilters(newFilters);
  };

  const handleFilterOperatorChange = (index, event) => {
    const newFilters = [...(activeFilters || [])];
    newFilters[index].operator = event.target.value;
    setActiveFilters(newFilters);
  };

  const handleFilterCheckboxChange = (index, event) => {
    const newFilters = [...(activeFilters || [])];
    newFilters[index].checked = event.target.checked;
    newFilters[index].value = event.target.checked ? 'true' : '';
    setActiveFilters(newFilters);
  };

  const removeFilter = (index) => {
    const newFilters = [...(activeFilters || [])];
    newFilters.splice(index, 1);
    setActiveFilters(newFilters);
  };

  const clearFilters = () => {
    setActiveFilters([]);
    setModalHeaderTitle('Recipes');
    setRecipes([]);
    setCurrentPage(1);
  };

  const handleHeaderTitleChange = (event) => {
    setModalHeaderTitle(event.target.value);
  };

  return (
    <div className={styles['search-container']}>
      {/* Header and Search Input Container */}
      <div className={styles['header-search-container']}>
        <h2
          className={styles['header-title']}
          onClick={toggleFilterModal}
          title="Click to edit filters"
          tabIndex="0"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleFilterModal();
            }
          }}
          role="button"
          aria-label="Edit filters"
        >
          {headerTitle}
        </h2>
        <input
          type="text"
          placeholder="Search recipes by title"
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles['recipe-search-input']}
        />
      </div>
  
      {/* Filter Modal */}
      {showFilterModal && (
        <div className={styles['filter-modal']}>
          <div className={styles['filter-modal-content']}>
            <h3>Filter Options</h3>
  
            {/* Header Title Input */}
            <div className={styles['header-title-input']}>
              <label htmlFor="headerTitle">Header Title:</label>
              <input
                type="text"
                id="headerTitle"
                value={modalHeaderTitle}
                onChange={handleHeaderTitleChange}
                className={styles['filter-input']}
              />
            </div>
  
            {/* Add Another Filter Button */}
            <button onClick={addFilter} className={styles['add-filter-button']}>
              Add Another Filter
            </button>
  
            <div className={styles['filter-options']}>
              {(activeFilters || []).map((filter, index) => (
                <div key={index} className={styles['filter-item']}>
                  {/* Filter Type Dropdown */}
                  <select
                    value={filter.filterType}
                    onChange={(event) => handleFilterTypeChange(index, event)}
                    className={styles['filter-select']}
                  >
                    <option value="">Select Filter</option>
                    {filterOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
  
                  {/* Render filter-specific inputs */}
                  {filter.filterType === 'maxMissingIngredients' && (
                    <input
                      type="number"
                      min="0"
                      placeholder="Max Missing Ingredients"
                      value={filter.value}
                      onChange={(event) => handleFilterValueChange(index, event)}
                      className={styles['filter-input']}
                    />
                  )}
  
                  {filter.filterType === 'filterByEquipment' && (
                    <label className={styles['filter-checkbox']}>
                      <input
                        type="checkbox"
                        checked={filter.checked}
                        onChange={(event) => handleFilterCheckboxChange(index, event)}
                      />
                      Only recipes with my equipment
                    </label>
                  )}
  
                  {filter.filterType === 'filterByGroceryList' && (
                    <label className={styles['filter-checkbox']}>
                      <input
                        type="checkbox"
                        checked={filter.checked}
                        onChange={(event) => handleFilterCheckboxChange(index, event)}
                      />
                      Only recipes in my grocery list
                    </label>
                  )}
  
                  {['ingredients', 'equipment', 'data_source', 'data_source_name', 'tags'].includes(
                    filter.filterType
                  ) && (
                    <input
                      type="text"
                      placeholder={
                        filter.filterType === 'ingredients'
                          ? 'Ingredients (comma-separated)'
                          : filter.filterType === 'equipment'
                          ? 'Equipment (comma-separated)'
                          : filter.filterType === 'data_source'
                          ? 'Data Source'
                          : filter.filterType === 'data_source_name'
                          ? 'Data Source Name'
                          : 'Tags (comma-separated)'
                      }
                      value={filter.value}
                      onChange={(event) => handleFilterValueChange(index, event)}
                      className={styles['filter-input']}
                    />
                  )}
  
                  {/* Numeric Filters with Operator */}
                  {[
                    'calories',
                    'fat',
                    'sat_fat',
                    'carbs',
                    'fiber',
                    'sugar',
                    'protein',
                    'prep_time_min',
                    'cook_time_min',
                    'total_time_min',
                    'servings',
                  ].includes(filter.filterType) && (
                    <div className={styles['filter-numeric']}>
                      <select
                        value={filter.operator}
                        onChange={(event) => handleFilterOperatorChange(index, event)}
                        className={styles['filter-operator-select']}
                      >
                        <option value="">Select Operator</option>
                        <option value="gte">&ge;</option>
                        <option value="lte">&le;</option>
                        <option value="gt">&gt;</option>
                        <option value="lt">&lt;</option>
                        <option value="eq">=</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        placeholder="Value"
                        value={filter.value}
                        onChange={(event) => handleFilterValueChange(index, event)}
                        className={styles['filter-input']}
                      />
                    </div>
                  )}
  
                  {/* Remove Filter Button */}
                  <button
                    onClick={() => removeFilter(index)}
                    className={styles['remove-filter-button']}
                  >
                    Remove Filter
                  </button>
                </div>
              ))}
            </div>
  
            {/* Filter Modal Buttons */}
            <div className={styles['filter-modal-buttons']}>
              <button onClick={saveFilterPreset} className={styles['save-button']}>
                Save Preset
              </button>
              {filterPreset && (
                <button
                  onClick={deleteFilterPreset}
                  className={styles['delete-preset-button']}
                >
                  Delete Preset
                </button>
              )}
              <button onClick={clearFilters} className={styles['clear-button']}>
                Clear Filters
              </button>
              <button onClick={toggleFilterModal} className={styles['close-button']}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
  
      {error && <p className={styles.error}>{error}</p>}
      {filteredRecipes.length === 0 && !error && <p>No recipes found.</p>}
  
      {/* Recipe list displayed in horizontal scrollable container */}
      <div
        className={styles['horizontal-scroll-container']}
        ref={scrollContainerRef}
      >
        {filteredRecipes.map((recipe) => (
          <div key={recipe.recipe_id} className={styles['recipe-card']}>
            <RecipeModalMini
              recipe={recipe}
              onClick={handleCardClick}
              showAddButton={true}        // Show "Add to Grocery Cart" button
              showQuantityControls={false} // Hide quantity controls and remove button
            />
          </div>
        ))}

        {isFetching && (
          <div className={styles.spinner}></div> // Spinner for fetching more recipes
        )}
      </div>

  
      {/* Modal for selected recipe */}
      {selectedRecipe && <RecipeModal recipe={selectedRecipe} onClose={handleCloseModal} />}
    </div>
  );  
}

export default Search;
