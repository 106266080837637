import React, { useState, useEffect } from "react";
import styles from "./YourKitchen.module.css";

function YourKitchen() {
  const [equipmentList, setEquipmentList] = useState([]);
  const [userSelectedEquipment, setUserSelectedEquipment] = useState(new Set());

  const [ingredientList, setIngredientList] = useState([]);
  const [userSelectedIngredients, setUserSelectedIngredients] = useState(new Set());

  const [equipmentSearchQuery, setEquipmentSearchQuery] = useState("");
  const [ingredientSearchQuery, setIngredientSearchQuery] = useState("");
  const [loadingEquipment, setLoadingEquipment] = useState(true);
  const [loadingIngredients, setLoadingIngredients] = useState(true);

  const [equipmentUsageCounts, setEquipmentUsageCounts] = useState({});
  const [ingredientCounts, setIngredientCounts] = useState({});
  const [showEquipmentUsageCounts, setShowEquipmentUsageCounts] = useState(false);
  const [showIngredientCounts, setShowIngredientCounts] = useState(false);

  const [equipmentProgress, setEquipmentProgress] = useState(0);
  const [equipmentTotal, setEquipmentTotal] = useState(0);

  const [ingredientProgress, setIngredientProgress] = useState(0);
  const [ingredientTotal, setIngredientTotal] = useState(0);

  const token = localStorage.getItem("token");

  // Fetch equipment usage counts
  useEffect(() => {
    if (showEquipmentUsageCounts) {
      const fetchEquipmentCountsWithLoading = async () => {
        setEquipmentProgress(0);
        setEquipmentTotal(equipmentList.length);

        const countsResponse = await Promise.all(
          equipmentList.map(async (equipment, index) => {
            const response = await fetch(
              `/api/equipment/usage-count?equipmentName=${encodeURIComponent(
                equipment.equipment_name
              )}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const data = await response.json();
            setEquipmentProgress((prev) => prev + 1);
            return { name: equipment.equipment_name, count: data.usageCount || 0 };
          })
        );

        const countsMap = countsResponse.reduce((acc, curr) => {
          acc[curr.name] = curr.count;
          return acc;
        }, {});

        setEquipmentUsageCounts(countsMap);
      };

      fetchEquipmentCountsWithLoading();
    }
  }, [showEquipmentUsageCounts, equipmentList, token]);

  // Fetch ingredient recipe counts
  useEffect(() => {
    if (showIngredientCounts) {
      const fetchIngredientCountsWithLoading = async () => {
        setIngredientProgress(0);
        setIngredientTotal(ingredientList.length);

        const countsResponse = await Promise.all(
          ingredientList.map(async (ingredient, index) => {
            const response = await fetch(
              `/api/ingredient/recipe-count?ingredientName=${encodeURIComponent(
                ingredient.ingredient_name
              )}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const data = await response.json();
            setIngredientProgress((prev) => prev + 1);
            return { name: ingredient.ingredient_name, count: data.recipeCount || 0 };
          })
        );

        const countsMap = countsResponse.reduce((acc, curr) => {
          acc[curr.name] = curr.count;
          return acc;
        }, {});

        setIngredientCounts(countsMap);
      };

      fetchIngredientCountsWithLoading();
    }
  }, [showIngredientCounts, ingredientList, token]);

  // Fetch equipment and user-selected equipment
  useEffect(() => {
    const fetchEquipmentList = async () => {
      try {
        const response = await fetch("/api/equipments-names", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (response.ok) {
          setEquipmentList(data);
        } else {
          console.error("Error fetching equipment:", data.error);
        }
      } catch (error) {
        console.error("Error fetching equipment:", error);
      } finally {
        setLoadingEquipment(false);
      }
    };

    const fetchUserSelectedEquipment = async () => {
      try {
        const response = await fetch("/api/user/equipment", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (response.ok) {
          setUserSelectedEquipment(new Set(data));
        } else {
          console.error("Error fetching user equipment:", data.error);
        }
      } catch (error) {
        console.error("Error fetching user equipment:", error);
      }
    };

    fetchEquipmentList();
    fetchUserSelectedEquipment();
  }, [token]);

  // Fetch ingredients and user-selected ingredients
  useEffect(() => {
    const fetchIngredientList = async () => {
      try {
        const response = await fetch("/api/ingredients", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (response.ok) {
          setIngredientList(data);
        } else {
          console.error("Error fetching ingredients:", data.error);
        }
      } catch (error) {
        console.error("Error fetching ingredients:", error);
      } finally {
        setLoadingIngredients(false);
      }
    };

    const fetchUserSelectedIngredients = async () => {
      try {
        const response = await fetch("/api/user/ingredients", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (response.ok) {
          setUserSelectedIngredients(new Set(data));
        } else {
          console.error("Error fetching user ingredients:", data.error);
        }
      } catch (error) {
        console.error("Error fetching user ingredients:", error);
      }
    };

    fetchIngredientList();
    fetchUserSelectedIngredients();
  }, [token]);

  // Fetch equipment usage counts
  useEffect(() => {
    if (showEquipmentUsageCounts) {
      const fetchEquipmentUsageCounts = async () => {
        try {
          const countsResponse = await Promise.all(
            equipmentList.map(async (equipment) => {
              const response = await fetch(
                `/api/equipment/usage-count?equipmentName=${encodeURIComponent(
                  equipment.equipment_name
                )}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              const data = await response.json();
              return { name: equipment.equipment_name, count: data.usageCount || 0 };
            })
          );
      
          const countsMap = countsResponse.reduce((acc, curr) => {
            acc[curr.name] = curr.count;
            return acc;
          }, {});
      
          setEquipmentUsageCounts(countsMap); // Store counts in state
        } catch (error) {
          console.error("Error fetching equipment usage counts:", error);
        }
      };
      

      fetchEquipmentUsageCounts();
    }
  }, [showEquipmentUsageCounts, equipmentList, token]);

  // Fetch ingredient recipe counts
  useEffect(() => {
    if (showIngredientCounts) {
      const fetchIngredientCounts = async () => {
        try {
          const countsResponse = await Promise.all(
            ingredientList.map(async (ingredient) => {
              const response = await fetch(
                `/api/ingredient/recipe-count?ingredientName=${encodeURIComponent(
                  ingredient.ingredient_name
                )}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              const data = await response.json();
              return { name: ingredient.ingredient_name, count: data.recipeCount || 0 };
            })
          );

          const countsMap = countsResponse.reduce((acc, curr) => {
            acc[curr.name] = curr.count;
            return acc;
          }, {});

          setIngredientCounts(countsMap);
        } catch (error) {
          console.error("Error fetching ingredient recipe counts:", error);
        }
      };

      fetchIngredientCounts();
    }
  }, [showIngredientCounts, ingredientList, token]);

  const toggleEquipment = async (equipment_id) => {
    try {
      const response = await fetch("/api/equipment/toggle", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ equipment_id }),
      });

      if (response.ok) {
        setUserSelectedEquipment((prev) => {
          const updated = new Set(prev);
          if (updated.has(equipment_id)) {
            updated.delete(equipment_id);
          } else {
            updated.add(equipment_id);
          }
          return updated;
        });
      }
    } catch (error) {
      console.error("Error toggling equipment:", error);
    }
  };

  const toggleIngredient = async (ingredient_id) => {
    try {
      const response = await fetch("/api/ingredients/toggle", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ingredient_id }),
      });

      if (response.ok) {
        setUserSelectedIngredients((prev) => {
          const updated = new Set(prev);
          if (updated.has(ingredient_id)) {
            updated.delete(ingredient_id);
          } else {
            updated.add(ingredient_id);
          }
          return updated;
        });
      }
    } catch (error) {
      console.error("Error toggling ingredient:", error);
    }
  };

  const filteredEquipmentList = equipmentList
    .filter((item) =>
      item.equipment_name.toLowerCase().includes(equipmentSearchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (showEquipmentUsageCounts) {
        const countA = equipmentUsageCounts[a.equipment_name] || 0;
        const countB = equipmentUsageCounts[b.equipment_name] || 0;
        return countB - countA; // Sort by usage count descending
      }
      const aSelected = userSelectedEquipment.has(a.id);
      const bSelected = userSelectedEquipment.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1; // Move selected items to the top
    });


    const filteredIngredientList = ingredientList
    .filter((item) =>
      item.ingredient_name.toLowerCase().includes(ingredientSearchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (showIngredientCounts) {
        const countA = ingredientCounts[a.ingredient_name] || 0;
        const countB = ingredientCounts[b.ingredient_name] || 0;
        return countB - countA; // Sort by recipe count descending
      }
      const aSelected = userSelectedIngredients.has(a.id);
      const bSelected = userSelectedIngredients.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1; // Move selected items to the top
    });

    return (
      <div className={styles["your-kitchen-container"]}>
        <h2 className={styles["your-kitchen-header"]}>Your Kitchen</h2>
    
        {/* Equipment Section */}
        <div className={styles["section"]}>
          <h3 className={styles["section-title"]}>Manage Equipment</h3>
          <div className={styles["search-bar"]}>
            <input
              type="text"
              placeholder="Search equipment..."
              value={equipmentSearchQuery}
              onChange={(e) => setEquipmentSearchQuery(e.target.value)}
              className={styles["search-input"]}
            />
            <button
              className={styles["button"]}
              onClick={() => setShowEquipmentUsageCounts((prev) => !prev)}
            >
              {showEquipmentUsageCounts ? "Hide Usage Counts" : "Show Usage Counts"}
            </button>
          </div>
    
          {showEquipmentUsageCounts && equipmentProgress < equipmentTotal && (
            <div className={styles["progress"]}>
              Loading: {equipmentProgress}/{equipmentTotal}
            </div>
          )}
    
          {loadingEquipment ? (
            <div className={styles.spinner}></div>
          ) : (
            <div className={styles["item-list"]}>
              {filteredEquipmentList.map((item) => (
                <button
                  key={item.id}
                  className={`${styles["item-button"]} ${
                    userSelectedEquipment.has(item.id)
                      ? styles["item-button-selected"]
                      : ""
                  }`}
                  onClick={() => toggleEquipment(item.id)}
                >
                  {item.equipment_name}
                  {showEquipmentUsageCounts && (
                    <span className={styles["recipe-count"]}>
                      {" "}
                      ({equipmentUsageCounts[item.equipment_name] || 0} recipes)
                    </span>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
    
        {/* Ingredient Section */}
        <div className={styles["section"]}>
          <h3 className={styles["section-title"]}>Manage Ingredients</h3>
          <div className={styles["search-bar"]}>
            <input
              type="text"
              placeholder="Search ingredients..."
              value={ingredientSearchQuery}
              onChange={(e) => setIngredientSearchQuery(e.target.value)}
              className={styles["search-input"]}
            />
            <button
              className={styles["button"]}
              onClick={() => setShowIngredientCounts((prev) => !prev)}
            >
              {showIngredientCounts ? "Hide Recipe Counts" : "Show Recipe Counts"}
            </button>
          </div>
    
          {showIngredientCounts && ingredientProgress < ingredientTotal && (
            <div className={styles["progress"]}>
              Loading: {ingredientProgress}/{ingredientTotal}
            </div>
          )}
    
          {loadingIngredients ? (
            <div className={styles.spinner}></div>
          ) : (
            <div className={styles["item-list"]}>
              {filteredIngredientList.map((item) => (
                <button
                  key={item.id}
                  className={`${styles["item-button"]} ${
                    userSelectedIngredients.has(item.id)
                      ? styles["item-button-selected"]
                      : ""
                  }`}
                  onClick={() => toggleIngredient(item.id)}
                >
                  {item.ingredient_name}
                  {showIngredientCounts && (
                    <span className={styles["recipe-count"]}>
                      {" "}
                      ({ingredientCounts[item.ingredient_name] || 0} recipes)
                    </span>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );    
}

export default YourKitchen;
