import React, { useState, useEffect } from 'react';
import styles from './CombinedIngredientsModal.module.css';

const CombinedIngredientsModal = ({ onClose }) => {
  const [userCarts, setUserCarts] = useState([]); // User carts list
  const [combinedIngredients, setCombinedIngredients] = useState({ userNeeds: [], userHas: [] }); // Start with an empty list

  useEffect(() => {
    fetchUserCarts();
  }, []);

  // Fetch user carts and ensure `combined` is a boolean
  const fetchUserCarts = async () => {
    try {
      const response = await fetch('/api/user-carts', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setUserCarts(
          (data || []).map((cart) => ({
            ...cart,
            combined: Boolean(cart.combined),
          }))
        );
      } else {
        console.error('Failed to fetch user carts:', data.message);
      }
    } catch (error) {
      console.error('Error fetching user carts:', error);
    }
  };

  const toggleCombinedStatus = async (cartId, currentStatus) => {
    try {
      // Optimistically update the cart list
      setUserCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.id === cartId ? { ...cart, combined: !currentStatus } : cart
        )
      );

      const response = await fetch('/api/user-carts/toggle-combined', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ cart_id: cartId, combined: !currentStatus }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to toggle combined status.');
      }

      const updatedCart = await response.json();

      // Update the cart list with the server's response
      setUserCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.id === updatedCart.id ? updatedCart : cart
        )
      );
    } catch (error) {
      console.error('Error toggling combined status:', error);
      alert('Failed to update combined status.');

      // Revert optimistic update
      setUserCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.id === cartId ? { ...cart, combined: currentStatus } : cart
        )
      );
    }
  };

  // Fetch combined ingredients based on `combined` carts
  const fetchCombinedIngredients = async () => {
    const selectedCartIds = userCarts
      .filter((cart) => cart.combined)
      .map((cart) => cart.id);

    if (selectedCartIds.length === 0) {
      alert('Please select at least one cart.');
      return;
    }

    try {
      const query = new URLSearchParams({ cart_ids: selectedCartIds }).toString();
      const response = await fetch(`/api/grocery-cart/combined-ingredients?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch combined ingredients.');
      }

      const data = await response.json();
      setCombinedIngredients(data); // Update only after fetching
    } catch (error) {
      console.error('Error fetching combined ingredients:', error);
      alert('Failed to combine ingredients. Please try again.');
    }
  };

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-container']}>
        <button className={styles['close-button']} onClick={onClose}>
          &times;
        </button>
        <h2 className={styles['modal-title']}>Grocery List</h2>

        {/* Cart Selection */}
        <div className={styles['cart-selection']}>
          <h3 className={styles['section-title']}>Select one or more Shopping Carts</h3>
          <ul className={styles['cart-list']}>
            {userCarts.map((cart) => (
              <li key={cart.id} className={styles['cart-item']}>
                <button
                  className={`${styles['cart-item-button']} ${
                    cart.combined ? styles['active'] : ''
                  }`}
                  onClick={() => toggleCombinedStatus(cart.id, cart.combined)}
                >
                  {cart.name}
                </button>
              </li>
            ))}
          </ul>
          <button
            className={styles['combine-button']}
            onClick={fetchCombinedIngredients}
          >
            Generate Grocery List
          </button>
        </div>

        {/* Display Combined Ingredients */}
        {combinedIngredients.userNeeds?.length > 0 && (
          <>
            <h3 className={styles['section-title']}>Ingredients You Need</h3>
            <ul className={styles['ingredients-list']}>
              {combinedIngredients.userNeeds.map((ingredient, index) => (
                <li
                  key={`${ingredient.ingredient_name}-${index}`}
                  className={styles['ingredient-item-needed']}
                >
                  <div className={styles['ingredient-layout']}>
                    <span className={styles['ingredient-details']}>
                      {ingredient.ingredient_name}
                      {ingredient.brand && (
                        <span className={styles['ingredient-brand']}>
                          {' '}({ingredient.brand})
                        </span>
                      )}
                      {ingredient.optional && (
                        <span className={styles['optional-label']}>
                          {' '} - optional
                        </span>
                      )}
                    </span>
                    <span className={styles['ingredient-quantity']}>
                      {ingredient.quantity} {ingredient.unit}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {combinedIngredients.userHas?.length > 0 && (
          <>
            <h3 className={styles['section-title']}>Ingredients You Have</h3>
            <ul className={styles['ingredients-list']}>
              {combinedIngredients.userHas.map((ingredient, index) => (
                <li
                  key={`${ingredient.ingredient_name}-${index}`}
                  className={styles['ingredient-item-have']}
                >
                  <div className={styles['ingredient-layout']}>
                    <span className={styles['ingredient-details']}>
                      {ingredient.ingredient_name}
                      {ingredient.brand && (
                        <span className={styles['ingredient-brand']}>
                          {' '}({ingredient.brand})
                        </span>
                      )}
                      {ingredient.optional && (
                        <span className={styles['optional-label']}>
                          {' '} - optional
                        </span>
                      )}
                    </span>
                    <span className={styles['ingredient-quantity']}>
                      {ingredient.quantity} {ingredient.unit}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {/* If no ingredients */}
        {combinedIngredients.userNeeds?.length === 0 &&
          combinedIngredients.userHas?.length === 0 && (
            <p className={styles['no-ingredients-message']}>
              No ingredients to display. Please combine a cart.
            </p>
          )}
      </div>
    </div>
  );
};

export default CombinedIngredientsModal;
