import React, { useState } from 'react';
import styles from './RecipeModal.module.css';

function RecipeModal({ recipe, onClose }) {
  const [userServings, setUserServings] = useState(recipe?.servings || 1);

  if (!recipe) return null;

  // Helper function to calculate values based on servings
  const calculatePerServing = (value) => (value ? (value / recipe.servings) * userServings : null);

  // Helper function to calculate time
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''} ` : ''}${
      remainingMinutes > 0 ? `${remainingMinutes} min` : ''
    }`;
  };

  const handleServingsChange = (e) => {
    const newServings = parseInt(e.target.value, 10);
    if (!isNaN(newServings) && newServings > 0) {
      setUserServings(newServings);
    }
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined || isNaN(Number(num))) return ''; // Return an empty string for invalid numbers
    return parseFloat(Number(num).toFixed(2)).toString(); // Ensure it's a number, round to 2 decimal places, and remove trailing .00
  };

  const formatUnit = (quantity, unit) => {
    if (quantity === 1) {
      return unit.replace(/s$/, ''); // Remove trailing 's' for singular units
    }
    return unit;
  };

  const isSingleServing = (userServings, originalServings) => userServings === originalServings;

  const hasNutritionInfo = (recipe) => {
    const nutritionFields = [
      recipe.calories,
      recipe.fat,
      recipe.sat_fat,
      recipe.carbs,
      recipe.fiber,
      recipe.sugar,
      recipe.protein,
    ];
    return nutritionFields.some((field) => field && field !== 'no answer');
  };

  return (
    <div className={styles['recipe-modal-overlay']}>
      <div className={styles['recipe-modal-container']}>
        <button className={styles['recipe-modal-close-button']} onClick={onClose}>
          &times;
        </button>

        {/* Title and Data Source Section */}
        <div className={styles['recipe-modal-header']}>
          <div className={styles['recipe-modal-title-container']}>
            <h2>{recipe.title}</h2>
          </div>
          {recipe.data_source_name && (
            <div className={styles['recipe-modal-data-source-container']}>
              {recipe.data_source_link ? (
                <a
                  href={recipe.data_source_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles['data-source-button']}
                >
                  <strong>Data Source:</strong> {recipe.data_source_name}
                </a>
              ) : (
                <p>
                  <strong>Data Source:</strong> {recipe.data_source_name}
                </p>
              )}
            </div>
          )}
        </div>

        {/* Info Section */}
        <div className={styles['recipe-modal-info-section']}>
          {recipe.prep_time_min && (
            <p>
              <strong>Prep Time</strong> {formatTime(recipe.prep_time_min)}
            </p>
          )}
          {recipe.wait_time_min && (
            <p>
              <strong>Wait Time</strong> {formatTime(recipe.wait_time_min)}
            </p>
          )}
          {recipe.cook_time_min && (
            <p>
              <strong>Cook Time</strong> {formatTime(recipe.cook_time_min)}
            </p>
          )}
          {recipe.total_time_min && (
            <p>
              <strong>Total Time</strong> {formatTime(recipe.total_time_min)}
            </p>
          )}
          <div className={styles['servings-section']}>
            <strong>Servings</strong>
            <input
              type="number"
              min="1"
              value={userServings}
              onChange={handleServingsChange}
              className={styles['servings-input']}
            />
            <span> / {recipe.servings}</span>
          </div>
        </div>

        {/* Nutrition Section */}
        {hasNutritionInfo(recipe) && (
          <div className={styles['recipe-modal-nutrition']}>
            {recipe.calories && (
              <p>
                <strong>Calories</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.calories)}`
                  : `${formatNumber(calculatePerServing(recipe.calories))} / ${formatNumber(recipe.calories)}`}
              </p>
            )}
            {recipe.fat && (
              <p>
                <strong>Fat</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.fat)}g`
                  : `${formatNumber(calculatePerServing(recipe.fat))}g / ${formatNumber(recipe.fat)}g`}
              </p>
            )}
            {recipe.sat_fat && (
              <p>
                <strong>Sat. Fat</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.sat_fat)}g`
                  : `${formatNumber(calculatePerServing(recipe.sat_fat))}g / ${formatNumber(recipe.sat_fat)}g`}
              </p>
            )}
            {recipe.carbs && (
              <p>
                <strong>Carbs</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.carbs)}g`
                  : `${formatNumber(calculatePerServing(recipe.carbs))}g / ${formatNumber(recipe.carbs)}g`}
              </p>
            )}
            {recipe.fiber && (
              <p>
                <strong>Fiber</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.fiber)}g`
                  : `${formatNumber(calculatePerServing(recipe.fiber))}g / ${formatNumber(recipe.fiber)}g`}
              </p>
            )}
            {recipe.sugar && (
              <p>
                <strong>Sugar</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.sugar)}g`
                  : `${formatNumber(calculatePerServing(recipe.sugar))}g / ${formatNumber(recipe.sugar)}g`}
              </p>
            )}
            {recipe.protein && (
              <p>
                <strong>Protein</strong>{' '}
                {isSingleServing(userServings, recipe.servings)
                  ? `${formatNumber(recipe.protein)}g`
                  : `${formatNumber(calculatePerServing(recipe.protein))}g / ${formatNumber(recipe.protein)}g`}
              </p>
            )}
          </div>
        )}

        {/* Instructions Section */}
        {recipe.instructions?.length > 0 && (
          <div className={styles['recipe-modal-instructions']}>
            <h4>Instructions</h4>
            <ul>
              {recipe.instructions.map((instr, index) => (
                <li key={index}>
                  <strong>Step {instr.step_number}:</strong> {instr.instruction}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Ingredients Section */}
        {recipe.ingredients && (
          <div className={styles['recipe-modal-ingredients']}>
            <h4>Ingredients</h4>
            <ul>
              {[...(recipe.ingredients.ingavailable || []), ...(recipe.ingredients.ingmissing || [])].map((ing, index) => (
                <li
                  key={index}
                  className={
                    recipe.ingredients.ingavailable.includes(ing)
                      ? styles['ingredient-available']
                      : styles['ingredient-missing']
                  }
                >
                  {isSingleServing(userServings, recipe.servings)
                    ? `${formatNumber(ing.quantity)} ${formatUnit(ing.quantity, ing.unit)} ${ing.ingredient_name}`
                    : `${formatNumber(calculatePerServing(ing.quantity))} / ${formatNumber(ing.quantity)} ${formatUnit(calculatePerServing(ing.quantity), ing.unit)} ${ing.ingredient_name}`}
                  {ing.brand && ` (${ing.brand})`}
                  {ing.method && ` (${ing.method})`}
                  {ing.optional && (
                    <span className={styles['optional-label']}>
                      {' '}
                      <i>-Optional</i>
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Equipment Section */}
        {recipe.equipment && (
          <div className={styles['recipe-modal-equipment']}>
            <h4>Equipment</h4>
            <ul>
              {[...(recipe.equipment.equavailable || []), ...(recipe.equipment.equmissing || [])].map((equip, index) => (
                <li
                  key={index}
                  className={
                    recipe.equipment.equavailable.includes(equip)
                      ? styles['equipment-available']
                      : styles['equipment-missing']
                  }
                >
                  {equip.equipment_name}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Tags Section */}
        {recipe.tags?.length > 0 && (
          <div className={styles['recipe-modal-tags']}>
            <div className={styles['recipe-modal-tags-container']}>
              {recipe.tags.map((tag, index) => (
                <span className={styles['recipe-modal-tag']} key={index}>
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecipeModal;
